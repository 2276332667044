<template>
  <div :key="'detailInvoiceConceptsTable_' + forceUpdate">
    <b-row :class="[{'block-concepts-add':crud.form.conceptsTMP.editing}]">
      <b-col md="8">
        <b-row>
          <b-col md="4">
            <FindObject :key="crud.form.conceptsTMP.forceRenderSelectConcepts"
                        render="search"
                        type="concepts" 
                        @select-object="loadConcepts($event)" 
                        :valueID="crud.form.conceptsTMP.concepts_id"/>           
          </b-col>                     
          <b-col md="8">
            <b-form-group label="Descripción">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.conceptsTMP.description"
                            required
                            placeholder="Ingresar una descripción">
              </b-form-input>
            </b-form-group>
          </b-col>  
        </b-row>
      </b-col>
      <b-col md="4">
        <b-row>
          <b-col md="4">
            <b-form-group label="Cantidad">
              <b-form-input type="number"
                            size="sm"
                            v-model="crud.form.conceptsTMP.quantity"
                            required
                            min="1"
                            placeholder="Ingresar cantidad">
              </b-form-input>
            </b-form-group>
          </b-col>   
          <b-col md="6">
            <b-form-group label="Importe">
              <b-input-group prepend="$" size="sm">
                <b-form-input type="number"                              
                              v-model="crud.form.conceptsTMP.price"
                              required
                              min="0"
                              step="0.01"
                              placeholder="Ingresar un Importe">
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>  
          <b-col md="1" class="crud-concepts-button-add-item">
            <b-button @click="addItem()" 
                      size="sm"
                      v-b-tooltip.hover 
                      :variant="crud.form.conceptsTMP.button.variant"
                      :title="crud.form.conceptsTMP.button.title">
              <i :class="crud.form.conceptsTMP.button.icon"></i>
            </b-button>                                                         
          </b-col>   
        </b-row>
      </b-col>
      <b-col lg="12" v-if="crud.form.conceptsTMP.editing" class="block-concepts-add-div">
        <b-icon icon="circle-fill" animation="throb" font-scale="2"></b-icon>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="12">
        <b-table class="mb-0 crud-concepts-customers-table-concepts"
                ref="table"
                responsive
                head-variant="dark"
                :hover="true"
                :small="true"
                :fixed="false"
                :items="tableConcepts.items"
                :fields="tableConcepts.fields">  

          <template v-slot:cell(concepts)="data">  
            {{data.item.description}}            
          </template>                

          <template v-slot:cell(quantity)="data">                        
            {{data.item.quantity}}
          </template>                                                      

          <template v-slot:cell(price)="data">  
            {{Intl.NumberFormat('es-AR',{style:'currency',currency:getCurrency}).format(data.item.price)}}
          </template>

          <template v-slot:cell(f_action)="data">  
            <b-dropdown right 
                        text="Acción" 
                        size="sm" 
                        variant="outline-dark"  
                        :disabled="crud.form.conceptsTMP.button.editing"
                        @show="setConfigTableDetailsShow"
                        @hide="setConfigTableDetailsHide">     

              <b-dropdown-header v-if="isEdit">Acciones</b-dropdown-header>  
              <b-dropdown-item @click="editItem(data.item, data.index)">
                <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
              </b-dropdown-item>
              <b-dropdown-item @click="deleteItem(data.index)" style="color:red;">
                <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
              </b-dropdown-item>     
            </b-dropdown>                                
          </template> 
        </b-table>
      </b-col>              
    </b-row>
  </div>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'
  import Param from '@/config/parameters'
  import moment from 'moment'

  export default {
    props: {
      customer_id:{
        type: Number,
        required: true,
      },    
      details: {
        type: Array,
        required: true,
      },
      isEdit: {
        type: Boolean,
        default: false,        
      },
      forceUpdate: {
        type: Number,
        default: 0,
      },      
    },
    components: {
      FindObject,      
    },
    data: () => {
      return {      
        tableConcepts : {
          items: [],
          fields: []
        },         
        crud: {
          form: {
            concepts: [],
            conceptsTMP: {
              id: 0,
              concepts: null,
              concepts_id: 0,              
              quantity: 0,
              price: 0,        
              description: '',
              forceRenderSelectConcepts: 0,               
              button: {
                icon: 'fa fa-plus',
                title: 'Agreagr Item',
                variant: 'success',
                editing: false
              },              
              editing: false,                                          
            }
          },           
        },                             
      }
    },
    computed: {
      getCurrency() {
        return 'ARS'
      }
    },    
    mounted() {      
      this.loadFieldsTable()

      setTimeout(() => {
        this.loadDetails()
      }, 500)      
    },
    methods: {
      loadFieldsTable() {
        this.tableConcepts.fields.push({key: 'concepts', label: 'Concepto', class:"align-middle", width:"150px"})                    
        this.tableConcepts.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle text-center", width:"100px"}) 
        this.tableConcepts.fields.push({key: 'price', label: 'Importe', class:"align-middle text-right", width:"100px"}) 
        this.tableConcepts.fields.push({key: 'f_action', label:'', class:"align-middle text-right", width:"100px"}) 
      },   

      addItem(){
        if(!this.crud.form.conceptsTMP.concepts) {
          this.$awn.alert('No se cargó el concepto')          
          return false
        }

        // cantidad
        if (this.crud.form.conceptsTMP.quantity.toString() == '' || parseFloat(this.crud.form.conceptsTMP.quantity)<=0) {
          this.$awn.alert('La cantidad debe ser mayor a cero')          
          return false
        }          
          
        // precio
        if (this.crud.form.conceptsTMP.price.toString() == '' || parseFloat(this.crud.form.conceptsTMP.price)<0) {                   
          this.$awn.alert('El importe no puede ser menor a cero (0)')          
          return false
        }

        // duplicado
        if(!this.validDuplicatedItem()) {
          this.$awn.alert('Item Duplicado') 
          return false          
        }        

        var arrItem = { 
          id: this.crud.form.conceptsTMP.id,
          concepts: this.crud.form.conceptsTMP.concepts,           
          quantity: parseFloat(this.crud.form.conceptsTMP.quantity), 
          price: parseFloat(this.crud.form.conceptsTMP.price), 
          description: this.crud.form.conceptsTMP.description,
        }

        this.crud.form.concepts.push(arrItem)             
        this.$emit('load-detail', this.crud.form.concepts)

        this.tableConcepts.items = this.crud.form.concepts
        this.crud.form.conceptsTMP.forceRenderSelectConcepts = 0

        this.loadConcepts(null)

        this.crud.form.conceptsTMP.button.title = "Agregar Item"
        this.crud.form.conceptsTMP.button.icon = "fa fa-plus"
        this.crud.form.conceptsTMP.button.variant = "success"
        this.crud.form.conceptsTMP.button.editing = false    
        
        this.crud.form.conceptsTMP.editing = false
      },
      editItem (item, index) {
        this.crud.form.conceptsTMP.editing = true
                                         
        this.crud.form.conceptsTMP.concepts_id = item.concepts.id
        this.crud.form.conceptsTMP.concepts = item.concepts
        this.crud.form.conceptsTMP.forceRenderSelectConcepts = item.concepts.id    
                   
        this.crud.form.conceptsTMP.id = item.id            
        this.crud.form.conceptsTMP.quantity = item.quantity    
        this.crud.form.conceptsTMP.price = item.price   
        this.crud.form.conceptsTMP.description = item.description  

        this.loadConcepts(item.concepts)

        this.crud.form.conceptsTMP.button.title = "Editar Item"
        this.crud.form.conceptsTMP.button.icon = "fa fa-save"
        this.crud.form.conceptsTMP.button.variant = "warning"
        this.crud.form.conceptsTMP.button.editing = true
                     
        this.crud.form.concepts.splice(index, 1)

        this.$emit('load-detail', this.crud.form.concepts)
      },
      deleteItem (index) {
        this.crud.form.concepts.splice(index, 1)
        this.$emit('load-detail', this.crud.form.concepts)
      },

      loadConcepts (object) {
        if(object){
          this.crud.form.conceptsTMP.editing = true

          this.crud.form.conceptsTMP.concepts = object
          this.crud.form.conceptsTMP.concepts_id = object.id    
          
          if(!this.crud.form.conceptsTMP.description)
            this.crud.form.conceptsTMP.description = object.name    

          this.crud.form.conceptsTMP.forceRenderSelectConcepts = object.id
          
          if(this.customer_id) {                              
            this.crud.form.conceptsTMP.editing = false
          } else {                      
            this.crud.form.conceptsTMP.concepts = null
          }                                           
        } else {
          this.crud.form.conceptsTMP.concepts_id = 0
          this.crud.form.conceptsTMP.concepts = null 
                 
          this.crud.form.conceptsTMP.description = ''
          this.crud.form.conceptsTMP.id = 0          
          this.crud.form.conceptsTMP.quantity = 0
          this.crud.form.conceptsTMP.price = 0          
        }
      },

      loadDetails() {        
        if(this.details) {     
                  
          if(this.customer_id) {                              
            this.crud.form.conceptsTMP.editing = false
          } else {                      
            this.crud.form.conceptsTMP.concepts = null
          }             

          var arrItem = null          
          this.details.forEach(element => {            
            arrItem = { 
              id: element.concepts.id,
              concepts: element.concepts,           
              quantity: parseFloat(element.quantity), 
              price: parseFloat(element.price), 
              description: element.description,
            }        

            this.crud.form.concepts.push(arrItem)                         
          });          

          this.$emit('load-detail', this.crud.form.concepts)

          this.tableConcepts.items = this.crud.form.concepts               
        }
      },
    
      setConfigTableDetailsShow() {
        var arrTable = document.getElementsByClassName('crud-concepts-customers-table-concepts')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '200px'
        }        
      },
      setConfigTableDetailsHide() {
        var arrTable = document.getElementsByClassName('crud-concepts-customers-table-concepts')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      }, 
      
      // VALID DUPLICATED
      validDuplicatedItem() {        
        var item = this.crud.form.conceptsTMP.concepts
        var status = true        
        if(this.crud.form.concepts) {          
          this.crud.form.concepts.forEach(element => {                                                
            if(element.concepts.id == item.id) {
              status = false
            }                          
          });
        }
        return status
      },         
    }
  }
</script>
<style scoped>
  .crud-concepts-customers-table-concepts {
    min-height: 100px;
  }   
  .crud-concepts-button-add-item {
    margin-top: 30px;
  }
  .block-concepts-add {
    background: white;
    filter: brightness(0.4);
    border-radius: 15px;
    padding-top: 10px;
    pointer-events: none;
    opacity: 0.5;
  }  
  .block-concepts-add-div {
    position: absolute;
    text-align: center;
    margin-top: 20px;    
  }  
</style>    